import { AxiosPromise, AxiosRequestConfig } from 'axios';

import bitesApi, { IRequestProps } from '../services/bitesApi';
import { EIntegrations } from '../types/integration';

export const shareWithIntegration = (
  body: IShareWithIntegrationBody,
  requestConfig?: AxiosRequestConfig,
  requestProps?: IRequestProps,
): AxiosPromise<IShareWithIntegrationResponse | Blob> =>
  bitesApi.post<IShareWithIntegrationResponse | Blob>('/distribution/share/', body, requestConfig, requestProps);

export const scheduleDistributionAllUsers = (
  body: IScheduledDistributionBody,
): AxiosPromise<IScheduledDistributionResponse> =>
  bitesApi.post<IScheduledDistributionResponse>('/distribution/schedule/', { ...body, unviewedUsersOnly: false });
export const scheduleDistributionUnviewedUsers = (
  body: IScheduledDistributionBody,
): AxiosPromise<IScheduledReminderResponse> =>
  bitesApi.post<IScheduledReminderResponse>('/distribution/schedule/', { ...body, unviewedUsersOnly: true });

export const notifyDistributionCreated = (body: {
  notificationMessageId: number;
  reminderDistributionId?: number;
  timezone: number;
  locale: string;
}) => bitesApi.post('/common_services/transactional/distribution/created', body, {}, { originalBody: true });

export const notifyScheduledDistributionCreated = (body: {
  scheduledDistributionId: number;
  reminderDistributionId?: number;
  timezone: number;
  locale: string;
}) => bitesApi.post('/common_services/transactional/distribution/scheduled/created', body, {}, { originalBody: true });

export const getWhatsAppTemplates = (organizationId: number) => {
  return bitesApi.get<TShareTemplates>(`/distribution/whatsapp/templates/?organization_id=${organizationId}`);
};

export const getSmsTemplates = (organizationId: number) => {
  return bitesApi.get<TShareTemplates>(`/distribution/sms/templates/?organization_id=${organizationId}`);
};

export const getEmailTemplates = (organizationId: number) => {
  return bitesApi.get<TShareTemplates>(`/distribution/email/templates/?organization_id=${organizationId}`);
};

export type IShareWithIntegrationBody =
  | IShareWithTeamsBody
  | IShareWithWhatsappBody
  | IShareWithSMSBody
  | IShareWithMailMergeBody
  | IShareWithEmailBody
  | IShareWithBeekeeperBody
  | IShareWithSlackBody;

export type IShareWithIntegrationResponse = {
  notificationMessageId: number;
};

export interface IShareWithTeamsBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Teams;
}

export interface IShareWithWhatsappBody extends IShareWithIntegrationBase {
  integration: EIntegrations.WhatsApp;
  template_id?: number;
  data1?: number[];
  data2?: number[];
  data3?: number[];
  data4?: number[];
  data5?: number[];
  data6?: number[];
  data7?: number[];
  data8?: number[];
}

export interface IShareWithSMSBody extends IShareWithIntegrationBase {
  integration: EIntegrations.SMS;
  template_id: number;
}

export interface IShareWithMailMergeBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Mail_merge;
}

export interface IShareWithEmailBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Email;
}

export interface IShareWithSlackBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Slack;
}

export interface IShareWithBeekeeperBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Beekeeper;
  customReplyMessage: string;
}

interface IShareWithIntegrationBase {
  dueToDateTime: string;
  customReplyMessage?: string;
  users: Array<number | string> | null;
  biteShare?: number;
  playlist?: number;
  orgId?: string;
}

// Note: currently scheduled distributions only work with an explicit list of users,
// rather than filters based on data1 - 8
type ScheduledDistParams = Omit<
  | IShareWithWhatsappBody
  | IShareWithSMSBody
  | IShareWithMailMergeBody
  | IShareWithEmailBody
  | IShareWithBeekeeperBody
  | IShareWithTeamsBody
  | IShareWithSlackBody,
  'data1' | 'data2' | 'data3' | 'data4' | 'data5' | 'data6' | 'data7' | 'data8'
> & { users: Array<number | string> };

export interface IScheduledDistributionBody {
  sendAt: string; // ISO DateTime String
  distParams: ScheduledDistParams;
  repeatRemainder?: {
    selectedDays: { [key: string]: boolean };
    occurrenceTimes: number;
  };
}

interface IScheduledDistributionResponse {
  distributionId: number;
}

interface IScheduledReminderResponse {
  distributionId: number;
}

export type TShareTemplate = {
  id: number;
  name: string;
  text: string;
  is_reminder: boolean;
  subject?: string;
  content1?: string;
  content2?: string;
  example?: string;
};

export type TShareTemplates = TShareTemplate[];
