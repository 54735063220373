import { useTranslation } from 'react-i18next';

import { SCHEDULE_DISTRIBUTION_DELAY_HOURS } from '../../../constants/constants';

export const useDistributionReminderDealy = () => {
  const { t } = useTranslation();
  const delayTextKey = '23';
  const text = t('share.noStatsModal.sendReminder', { delayText: delayTextKey });
  return { delay: SCHEDULE_DISTRIBUTION_DELAY_HOURS, text };
};
