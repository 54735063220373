import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../components/Buttons/Button';
import Modal from '../../../components/Modal';
import { biteSharesMapSelector, bitesMapSelector } from '../../bites/bites.slice';
import { playlistsMapSelector } from '../../playlists/playlists.slice';
import { quizzesMapSelector } from '../../quiz/quiz.slice';
import { createConfig, deleteConfig, updateConfig } from '../assignmentConfig.slice';

import AttributesSelect from './AttributesSelect';
import ContentSelect from './ContentSelect';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onReloadTable: () => void;
  selectedRowData: {
    id: string;
    attributeValueIds: number[][];
    exceptAttributeValueIds: number[][];
    content: { contentType: string; contentId: number; dueDateDelayMinutes: number }[];
  };
}

const UpdateConfigModal: React.FC<IProps> = ({ isOpen, onClose, selectedRowData, onReloadTable }) => {
  const { t } = useTranslation();
  const biteSharesMap = useSelector(biteSharesMapSelector);
  const bitesMap = useSelector(bitesMapSelector);
  const playlistsMap = useSelector(playlistsMapSelector);
  const quizzesMap = useSelector(quizzesMapSelector);

  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const [selectedAttributes, setSelectedAttributes] = useState([[]]);
  const [excludedAttributes, setExcludedAttributes] = useState([[]]);
  const [selectedContent, setSelectedContent] = useState([{ assignedContent: null, timeToWatch: undefined }]);

  const isSaveDisabled = useMemo(() => {
    return selectedContent.length === 1 && !selectedContent[0].assignedContent;
  }, [selectedContent]);

  const updateState = useCallback((prevState, index, value) => {
    const newState = [...prevState];
    newState[index] = value;
    const isNeedToCreateNew = !newState.some((item) => item.length === 0);
    return isNeedToCreateNew ? [...newState, []] : newState;
  }, []);

  const handleSelectIncludeAttributes = useCallback(
    (value, index) => {
      setSelectedAttributes((prev) => updateState(prev, index, value));
    },
    [updateState],
  );

  const handleSelectExcludeAttributes = useCallback(
    (value, index) => {
      setExcludedAttributes((prev) => updateState(prev, index, value));
    },
    [updateState],
  );

  const handleSelectContent = useCallback((value, index) => {
    setSelectedContent((prev) => {
      const newState = [...prev];
      newState[index].assignedContent = value;

      const isNeedToCreateNew = !newState.some((item) => item.assignedContent === null);

      if (isNeedToCreateNew) {
        return [...newState, { assignedContent: null, timeToWatch: undefined }];
      }

      return newState;
    });
  }, []);

  const handleChangeTimeToWatch = useCallback((value, index) => {
    setSelectedContent((prev) => {
      if (value !== undefined && value.length && !/^\d+$/.test(value)) {
        return prev;
      }
      const newState = [...prev];
      newState[index].timeToWatch = value;
      return newState;
    });
  }, []);

  const resetState = useCallback(() => {
    setSelectedAttributes([[]]);
    setExcludedAttributes([[]]);
    setSelectedContent([{ assignedContent: null, timeToWatch: undefined }]);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    resetState();
  }, [onClose, resetState]);

  const handleDelete = useCallback(() => {
    setIsDeleteModalOpened(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpened(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    setIsDeleting(true);
    dispatch(
      deleteConfig({
        configId: selectedRowData.id,
        onSuccess: () => {
          setIsDeleting(false);
          onReloadTable();
        },
        onError: () => {
          setIsDeleting(false);
        },
      }),
    );
    setIsDeleteModalOpened(false);
    handleClose();
  }, [dispatch, handleClose, onReloadTable, selectedRowData?.id]);

  const getContentType = useCallback((item) => {
    if (item.is_quiz) {
      return 'quiz';
    }

    if (item.totalBites) {
      return 'playlist';
    }

    if (item.biteShareId) {
      return 'biteShare';
    }

    return 'bite';
  }, []);

  const handleSave = useCallback(() => {
    const attributeValueIds = selectedAttributes.filter((item) => item.length);
    const exceptAttributeValueIds = excludedAttributes.filter((item) => item.length);

    const content = selectedContent
      .map((item) =>
        item.assignedContent
          ? {
              contentType:
                item.assignedContent.contentType === 'bite'
                  ? 'biteShare'
                  : item.assignedContent.contentType || getContentType(item.assignedContent),
              contentId:
                item.assignedContent.contentType === 'bite' || item.assignedContent.contentType === 'biteShare'
                  ? item.assignedContent.bitePreview || item.assignedContent.biteShareId
                  : item.assignedContent.id || item.assignedContent.biteShareId,
              dueDateDelayMinutes: item.timeToWatch === undefined ? undefined : Number(item.timeToWatch) * 60 * 24,
            }
          : null,
      )
      .filter((item) => item);

    if (selectedRowData) {
      setIsSaving(true);
      dispatch(
        updateConfig({
          config: { id: selectedRowData.id, attributeValueIds, exceptAttributeValueIds, content },
          onSuccess: () => {
            setIsSaving(false);
            onReloadTable();
            handleClose();
          },
          onError: () => {
            setIsSaving(false);
          },
        }),
      );
      return;
    }

    setIsCreating(true);
    dispatch(
      createConfig({
        config: {
          attributeValueIds,
          exceptAttributeValueIds,
          content,
        },
        onSuccess: () => {
          setIsCreating(false);
          onReloadTable();
          handleClose();
        },
        onError: () => {
          setIsCreating(false);
        },
      }),
    );
  }, [
    dispatch,
    excludedAttributes,
    getContentType,
    handleClose,
    onReloadTable,
    selectedAttributes,
    selectedContent,
    selectedRowData,
  ]);

  useEffect(() => {
    if (!selectedRowData) {
      return;
    }

    if (selectedRowData.attributeValueIds?.length) {
      setSelectedAttributes([...selectedRowData.attributeValueIds, []]);
    }

    if (selectedRowData.exceptAttributeValueIds?.length) {
      setExcludedAttributes([...selectedRowData.exceptAttributeValueIds, []]);
    }

    if (selectedRowData.content?.length) {
      const newContent = selectedRowData.content.map((item) => ({
        assignedContent:
          item.contentType === 'bite'
            ? bitesMap[item.contentId]
            : item.contentType === 'biteShare'
            ? biteSharesMap[item.contentId]
            : item.contentType === 'playlist'
            ? playlistsMap[item.contentId]
            : quizzesMap[item.contentId],
        timeToWatch:
          typeof item.dueDateDelayMinutes === 'number' ? String(item.dueDateDelayMinutes / 24 / 60) : undefined,
      }));

      setSelectedContent([...newContent, { assignedContent: null, timeToWatch: undefined }]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowData]);

  const handleRemoveAttributesSelect = useCallback((index) => {
    setSelectedAttributes((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleRemoveExcludedAttributesSelect = useCallback((index) => {
    setExcludedAttributes((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleRemoveContentSelect = useCallback((index) => {
    setSelectedContent((prev) => prev.filter((_, i) => i !== index));
  }, []);

  if (isDeleteModalOpened) {
    return (
      <S.Modal
        isOpen={isDeleteModalOpened}
        onClose={handleCloseDeleteModal}
        content={
          <S.DeleteModalContent>
            <S.Label>{t('assignmentConfig.updateConfigModal.areYouSure')}</S.Label>
            <S.ButtonContainer>
              <Button isLoading={isDeleting} isRed width={100} onClick={handleConfirmDelete}>
                {t('assignmentConfig.updateConfigModal.delete')}
              </Button>
              <Button width={100} onClick={handleCloseDeleteModal}>
                {t('assignmentConfig.updateConfigModal.cancel')}
              </Button>
            </S.ButtonContainer>
          </S.DeleteModalContent>
        }
      />
    );
  }

  return (
    <S.Modal
      isOpen={isOpen}
      onClose={handleClose}
      content={
        <S.Content>
          <S.SelectContainer>
            <S.Label>{t('assignmentConfig.updateConfigModal.targetUsers')}</S.Label>
            {selectedAttributes.map((item, index) => (
              <AttributesSelect
                key={index}
                index={index}
                selectedAttributes={item}
                onChange={handleSelectIncludeAttributes}
                onRemoveSelect={handleRemoveAttributesSelect}
                isDeleteEnabled={selectedAttributes.length !== index + 1}
                placeholder={t('assignmentConfig.updateConfigModal.targetSelectAttribute')}
                type={'target'}
              />
            ))}
          </S.SelectContainer>

          <S.SelectContainer>
            <S.Label>{t('assignmentConfig.updateConfigModal.excludeUsers')}</S.Label>
            {excludedAttributes.map((item, index) => (
              <AttributesSelect
                key={index}
                index={index}
                selectedAttributes={item}
                onChange={handleSelectExcludeAttributes}
                onRemoveSelect={handleRemoveExcludedAttributesSelect}
                isDeleteEnabled={excludedAttributes.length !== index + 1}
                placeholder={t('assignmentConfig.updateConfigModal.excludeSelectAttribute')}
                type={'exclude'}
              />
            ))}
          </S.SelectContainer>

          <S.SelectContainer>
            <S.Label>{t('assignmentConfig.updateConfigModal.content')}</S.Label>
            {selectedContent.map((item, index) => (
              <ContentSelect
                key={index}
                index={index}
                assignedContent={item.assignedContent}
                onChange={handleSelectContent}
                timeToWatch={item.timeToWatch}
                onChangeTimeToWatch={handleChangeTimeToWatch}
                onRemoveSelect={handleRemoveContentSelect}
                isDeleteEnabled={selectedContent.length !== index + 1}
                placeholder={t('assignmentConfig.updateConfigModal.selectContent')}
              />
            ))}
          </S.SelectContainer>

          <S.ButtonContainer>
            <Button isLoading={isSaving || isCreating} width={100} onClick={handleSave} disabled={isSaveDisabled}>
              {selectedRowData
                ? t('assignmentConfig.updateConfigModal.save')
                : t('assignmentConfig.updateConfigModal.create')}
            </Button>
            {selectedRowData && (
              <Button isRed width={100} onClick={handleDelete}>
                {t('assignmentConfig.updateConfigModal.delete')}
              </Button>
            )}
          </S.ButtonContainer>
        </S.Content>
      }
    />
  );
};

const S = {
  Modal: styled(Modal)``,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 600px;
    max-height: 90vh;
    overflow-y: auto;
  `,
  ButtonContainer: styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  `,
  SelectContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  `,
  Label: styled.span`
    font-size: 18px;
    margin-bottom: 10px;
  `,
  DeleteModalContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    width: 600px;
    height: 200px;
  `,
};

export default UpdateConfigModal;
